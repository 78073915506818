<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Forgot Password" icon=""/>

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <table border="0" align="center" height="100%">
                                <tr>
                                    <td><fa icon="shield" class="align-middle" style="font-size: 120px" /></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-lg-8">
                            <div v-if="errorStatus === '' && isSubmit === false" class="row">
                                <div class="col-12 pb-3">
                                    Please fill up your registered email address to reset your password
                                </div>
                                <div class="col-12 pb-3">
                                    <input v-model="email" class="form-control" placeholder="Email" />
                                </div>

                                <div class="col-12 col-lg-6 pb-2">
                                    <div style="width: 200px; height: 100px">
                                        <img :src="captchaImg" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                        <button class="btn btn-secondary btn-sm mb-2" @click="getCaptcha" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                            <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                                <fa icon="refresh" />
                                            </Popper>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6 pb-2">
                                    <input v-model="captcha" class="form-control" placeholder="Captcha">
                                </div>

                                <div class="col-lg-12 pb-3">
                                    <div class="pb-2">After you clicked on the Submit button, a reset password email with 24 hours validity will be sent to you.</div>
                                    <div>Kindly check your email and click on the Reset My Password button to update your new password.</div>
                                </div>
                                <!-- <div class="col-lg-9 pb-3">
                                    An email will be sent to you for reset password, and it is active for 24 hours
                                </div> -->

                                <div class="col-lg-12 pb-3 text-end">
                                    <button type="button" class="btn btn-info text-white" @click="submit">Submit</button>
                                </div>
                            </div>
                            <div v-else-if="errorStatus === '' && isSubmit === true" class="row">
                                <div class="col-lg-12 pb-3">
                                    <div class="pb-2">A reset password email with 24 hours validity has sent to you.</div>
                                    <div class="pb-2">Please check your email and click on the 'Reset My Password' button to update your new password.</div>
                                    <div>If you do not see the email in your inbox, kindly check your spam or junk folder.</div>
                                </div>
                            </div>

                            <div v-else class="row">
                                <div v-if="!showResend" class="col-12">
                                    <div class="mb-2">Your account is registered but not activated, please activate your account in order to set your password.</div>
                                    <div>If you do not receive activation email, please click <span class="isLink" @click="showResend = true"><u>here</u></span> to send again, then check your junk/spam folder.</div>
                                </div>
                                <div v-if="showResend" class="col-12">
                                    <div class="row">
                                        <div class="col-12 pb-3">
                                            <div class="col-12 pb-2">Fill in detail below to resend activation link</div>
                                        </div>
                                        <div class="col-12 pb-3">
                                            <input v-model="emailResend" class="form-control" placeholder="Email">
                                        </div>

                                        <div class="col-12 col-lg-6 pb-3">
                                            <div style="width: 200px; height: 100px">
                                                <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                                        <fa icon="refresh" />
                                                    </Popper>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="col-12 col-lg-6 pb-3">
                                            <input v-model="captchaResend" class="form-control" placeholder="Captcha">
                                            <div class="text-end"><button type="button" class="btn btn-info text-white mt-4" @click="resendActivation">Resend</button></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <div class="row">
                    <div class="col-12">
                        <div class="generalContentBox border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="circle-user" style="font-size: 25px"/></td>
                                    <td>
                                        <div>Existing user?</div> 
                                        <router-link :to="{ name: 'Login', query: route.query}">
                                            <div class="greyLink"><u>Login</u></div>    
                                        </router-link>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="generalContentBox border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="circle-question" style="font-size: 25px"/></td>
                                    <td>
                                        <div>Need more help?</div>
                                        <div>Email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'

export default {
    name: 'ForgotPassword',
    components: { TopNavigation, Alert, Popper },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getUserId } = useStore()

        const email = ref(null)
        const captchaImg = ref(null)
        const refId = ref(null)
        const captcha = ref(null)

        const showResend = ref(false)
        const errorStatus = ref('')
        const emailResend = ref(null)
        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)
        const isSubmit = ref(false)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const submit = () => {
            const p = {
                email: email.value,
                refId: refId.value,
                captcha: captcha.value,
                frontendUrl: '/auth/resetPassword'
            }

            axios.post('/auth/forgotPassword', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        isSubmit.value = true
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "An reset password email has sent to you, please click the link to reset password."
                        })

                    } else {
                        func.addLog('forgotPassword', 'submit', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'no_permission') {
                                errorStatus.value = res.data.message
                                getCaptchaResend()

                            } else if (res.data.message === 'user_is_online') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "We found your status is online. You are not allowed to reset password. (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'deleting_user') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "We found that your account is expired. Sorry to inform that we are clearing your account, kindly register a new account again after a minute later. (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'empty_email') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "Please enter email. (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'user_not_found') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "We found that your account is not exists. Kindly register a new account. (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'invalid_whitelabel') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "Domain not found. (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'activation_limit_exceeded') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "We found that your resend activation request is exceeded limit. (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'forgot_password_limit_exceeded') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "We found that your reset password request is exceeded limit. (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'password_limit_exceeded') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "You are resetting your passwords too frequently. Please take a short break. You may contact WeCare@SignOn.my if you need our help. (" + res.data.status + ")"
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('forgotPassword', 'submit - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getCaptcha = () => {
            axios.get('/reference/captcha/FORGOTPWD')
                .then((res) => {

                    if (res.data.status === 1) {
                        captchaImg.value = res.data.data.captcha.image
                        refId.value = res.data.data.refId

                    } else {
                        func.addLog('forgotPassword', 'getCaptcha', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {

                    func.addLog('forgotPassword', 'getCaptcha - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {

                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId

                    } else {
                        func.addLog('forgotPassword', 'getCaptchaResend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('forgotPassword', 'getCaptchaResend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const resendActivation = () => {
            const p = {
                username: emailResend.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: '/auth/activate'
            } 
            
            axios.post('/auth/signup/resend', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Activation email is sent, please check your junk/spam folder."
                        })

                    } else {
                        func.addLog('forgotPassword', 'resendActivation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'captcha_not_match' || res.data.message === 'ip_not_match' || res.data.message === 'refId_not_found') {
                                getCaptchaResend()
                                captcha.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Captcha not matched. Please refresh the captcha and try again. (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'refId_expired') {
                                getCaptchaResend()
                                captcha.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Captcha is expired. Please refresh the captcha and try again. (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'deleting_user') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'We found that your account is expired. Sorry to inform that we are clearing your account, kindly register a new account again after a minute later. (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'user_was_activated') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'We found that your account is already activated, resend activation is not allowed. (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'user_was_disabled') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'We found that your account is disabled, resend activation is not allowed. (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'activation_limit_exceeded') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Resend request is exceeded limit, only 3 requests can be requested within 24 hours. (' + res.data.status + ')'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('forgotPassword', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        onMounted(() => {
            getCaptcha()

            if (!(getUserId === null || getUserId === '')) {
                email.value = getUserId.value

            }

            
        })

        return { 
            alert, route, router, closeAlert, 
            captchaImg, captcha, refId, email, submit, getCaptcha, resendActivation,
            showResend, errorStatus, emailResend, getCaptchaResend, captchaImgResend, refIdResend, captchaResend,
            isSubmit
        }
    }
}
</script>

<style>

.greyBox {
  background-color: var(--auth-box-bg);
}

</style>